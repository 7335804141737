import React from "react"
const About = () => {
  return (
    <div className="about-body">
      <section className="about-content">
        <p>
          To observe is no different from breathing or feeling the sensation of
          touch. An inherent trait that facilitates us to perceive and learn
          from what’s around.
          <br />
          <br />
          Observant is an electronic music label founded by DJ/Producer duo
          Audio Units, also known as Ashwin Baburao & Ashrith Baburao. At the
          core, Observant is a small but loving team of DJs, music producers and
          visual artists.
          <br />
          <br />
          Observant was founded primarily as a broadening to showcase
          exceptional musical talent that stride to relentlessly express their
          undying love for the arts from across the world.
          <br />
        </p>
      </section>
    </div>
  )
}

export default About
