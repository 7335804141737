import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCBmCpT3BWQClZ50NbL97Xmep4hb_kFeWI",
  authDomain: "observant-4d73e.firebaseapp.com",
  databaseURL: "https://observant-4d73e.firebaseio.com",
  projectId: "observant-4d73e",
  storageBucket: "observant-4d73e.appspot.com",
  messagingSenderId: "265483751990",
  appId: "1:265483751990:web:4bd7725dd44b592f2bfe97",
  measurementId: "G-SNHQCYZ480"
}

firebase.initializeApp(firebaseConfig)

export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()

export const provider = new firebase.auth.GoogleAuthProvider()
export const signInWithGoogle = () => auth.signInWithPopup(provider)
export const signInWithEmailAndPassword = ({ email, password }) =>
  auth.signInWithEmailAndPassword(email, password)
export const signOut = () => auth.signOut()

window.firebase = firebase

export const createUserProfileDocument = async (user, additionalData) => {
  if (!user) return

  // Get a reference to the place in the database where a user profile might be
  const userRef = firestore.doc(`users/${user.uid}`)

  // Go and fetch document from that location
  const snapshot = await userRef.get()

  if (!snapshot.exists) {
    console.log(additionalData)
    const { displayName, photoURL } = additionalData
    const { email } = user
    const createdAt = new Date()
    try {
      await userRef.set({
        displayName,
        email,
        photoURL,
        createdAt
      })
    } catch (error) {
      console.error(`Error creating user`, error)
    }
  }

  return getUserDocument(user.uid)
}

export const getUserDocument = async uid => {
  if (!uid) return null
  try {
    const userDocument = await firestore
      .collection("users")
      .doc(uid)
      .get()
    return { uid, ...userDocument.data() }
  } catch (error) {
    console.error(`Error fetching user`, error)
  }
}

export default firebase
