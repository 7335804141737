import React from "react"
const Home = () => {
  return (
    <div className="container--fluid">
      <section className="main-logo-container">
        <img src="/assets/img/logo-main.png" alt="Main logo" />
      </section>

      <section className="hero-text">
        <p className="hero-text__content">
          <strong>
            Our consciousness is rooted in the expansion of abstract beauty
          </strong>
        </p>
        <p className="hero-text__content">
          <strong>
            The unexplainable grows through the mechanics of external reality
          </strong>
        </p>
        <p className="hero-text__content">
          <strong>Communication with tone and light is no coincidence</strong>
        </p>
        <p className="hero-text__content">
          <strong>We are all observant</strong>
        </p>
      </section>
    </div>
  )
}

export default Home
