import React from "react"
const ContactUs = () => {
  return (
    <div className="form-container">
      <form className="demo-form" name="contact" netlify>
        <div className="form-group">
          <input
            type="text"
            className="form-control"
            name="name"
            placeholder="Name"
          />
        </div>
        <div className="form-group">
          <input
            type="email"
            className="form-control"
            name="email"
            placeholder="Email"
          />
        </div>
        <div className="form-group">
          <textarea
            type="text"
            className="form-control"
            name="Message"
            placeholder="Message"
          ></textarea>
        </div>
        <div className="form-group">
          <button type="submit" className="button">
            Submit
          </button>
        </div>
      </form>
    </div>
  )
}

export default ContactUs
