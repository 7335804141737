import React, { Component } from "react"
import { ToastContainer, toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
import { firestore } from "../firebase"
class Demos extends Component {
  state = {
    artistName: "",
    email: "",
    soundcloudLink: "",
    facebookLink: "",
    message: "",
    city: ""
  }

  handleInputChange = event => {
    const { name, value } = event.target
    this.setState({ [name]: value })
  }
  handleSubmit = event => {
    const monthNames = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ]
    let dateObj = new Date()
    let month = monthNames[dateObj.getMonth()]
    let day = String(dateObj.getDate()).padStart(2, "0")
    let year = dateObj.getFullYear()
    let timeStamp = month + "\n" + day + "," + year
    event.preventDefault()
    let El = event.target
    El.style.padding = "0"
    El.style.height = "48px"
    event.target.innerHTML =
      '<img width="auto" height="100%" src="/assets/img/loading-small.gif" />'
    const demo = {
      ...this.state,
      status: "New",
      createdAt: timeStamp,
      favourite: false,
      deleted: false,
      viewed: false
    }
    firestore
      .collection("demos")
      .add(demo)
      .then(demoRef => {
        toast("🦄 Demo submitted! You'll hear back from us soon.")
        El.innerHTML = "Submit"
        El.style.padding = "10px 22px"
        El.style.height = "auto"
        this.setState({
          artistName: "",
          email: "",
          soundcloudLink: "",
          facebookLink: ""
        })
      })
  }
  render() {
    const {
      artistName,
      email,
      soundcloudLink,
      facebookLink,
      message,
      city
    } = this.state
    return (
      <div className="form-container">
        <form className="demo-form" name="demo">
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              name="artistName"
              value={artistName}
              placeholder="Artist Name"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="email"
              className="form-control"
              required
              name="email"
              value={email}
              placeholder="Email"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              name="city"
              value={city}
              placeholder="City"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              name="soundcloudLink"
              value={soundcloudLink}
              placeholder="Soundcloud Link"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <input
              type="text"
              className="form-control"
              required
              name="facebookLink"
              value={facebookLink}
              placeholder="Facebook Page Link"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <textarea
              type="text"
              className="form-control"
              required
              name="message"
              value={message}
              placeholder="Tell us about yourself"
              onChange={this.handleInputChange}
            />
          </div>
          <div className="form-group">
            <button
              type="submit"
              className="button"
              onClick={this.handleSubmit}
            >
              Submit
            </button>
          </div>
        </form>
        <div className="demos-notice-container">
          <p className="demos-notice">
            If you believe your music will surpass our intrinsic observation,
            send us a private SoundCloud Playlist link with a choice of at-least
            3 tracks. If you don't hear back from us in a week, then it's not
            for us.
          </p>
        </div>
        <ToastContainer autoClose={8000} />
      </div>
    )
  }
}

export default Demos
