import React, { Component } from "react"
import { BrowserRouter, Switch, Route } from "react-router-dom"
import { firestore } from "../firebase"
import Home from "./Home"
import Releases from "./Releases"
import About from "./About"
import Demos from "./Demos"
import ContactUs from "./ContactUs"
import { collectIdsAndDocs } from "../utilities"

class Application extends Component {
  state = {
    releases: []
  }
  unsubscribeFromFirestore = null

  componentDidMount = async () => {
    this.unsubscribeFromFirestore = firestore
      .collection("releases")
      .onSnapshot(snapshot => {
        const releases = snapshot.docs.map(collectIdsAndDocs)
        this.setState({ releases })
      })
  }

  componentWillUnmount = () => {
    this.unsubscribeFromFirestore()
  }

  render() {
    return (
      <div className="container--fluid">
        <BrowserRouter>
          <Switch>
            <Route exact path="/" render={() => <Home />} />
            <Route
              path="/music"
              render={() => <Releases releases={this.state.releases} />}
            />
            <Route path="/about" render={() => <About />} />
            <Route path="/demos" render={() => <Demos />} />
            <Route path="/contact-us" render={() => <ContactUs />} />
          </Switch>
        </BrowserRouter>
      </div>
    )
  }
}

export default Application
